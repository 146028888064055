<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card class="fixed-card">
      <v-card-title ref="cardTitle">
        {{ `量表分类管理` }}
        <AppTooltipBtn
          rounded
          btn-class="ml-8"
          color="primary"
          :icon="'mdi-calendar-plus'"
          :label="`新建分类`"
          @click="showAddDialog"
        />
        <AppTooltipBtn
          text
          btn-class="ml-4"
          color="primary"
          icon="mdi-refresh"
          label="刷新"
          tooltip="刷新列表"
          @click="getCategoryList"
        />
        <v-spacer></v-spacer>
        <v-text-field
          class="lbgroup-search-field"
          v-model="lbCategorySearchText"
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        点击下方表格内容可直接更新
        <v-data-table
          class="mx-4"
          :height="getDataTableHeight()"
          :key="dataTableKey"
          :headers="nodeLbCategoryHeaders"
          :items="lbCategoryList"
          item-key="guid"
          :search="lbCategorySearchText"
          :loading="isLbCategoryListLoading"
          :loading-text="`正在读取量表分类列表，请稍候...`"
          :no-data-text="`未找到任何量表分类`"
          :no-results-text="`未找到任何匹配的量表`"
          fixed-header
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.displayName`]="{ item }">
            <v-edit-dialog
              :return-value.sync="editingText"
              large
              persistent
              save-text="保存"
              cancel-text="取消"
              @open="editingTextOpen(item.displayName)"
              @save="updateLbCategoryDisplayName(item, editingText)"
              @cancel="editingCancel()"
            >
              <div>{{ item.displayName }}</div>
              <template v-slot:input>
                <div class="my-4 text-h6">{{ `更新量表分类名` }}</div>
                <v-text-field
                  single-line
                  dense
                  autofocus
                  :label="`量表分类名`"
                  v-model="editingText"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <v-edit-dialog
              :return-value.sync="editingText"
              large
              persistent
              save-text="保存"
              cancel-text="取消"
              @open="editingTextOpen(item.description)"
              @save="updateLbCategoryDescription(item, editingText)"
              @cancel="editingCancel()"
            >
              <div>{{ item.description }}</div>
              <template v-slot:input>
                <div class="my-4 text-h6">{{ `更新量表分类描述` }}</div>
                <v-textarea
                  counter
                  full-width
                  single-line
                  autofocus
                  :label="`量表分类描述`"
                  v-model="editingText"
                ></v-textarea>
              </template>
            </v-edit-dialog>
          </template>
          <!--=================== Item - Actions ==================-->
          <template v-slot:[`item.guid`]="{ item }">
            <AppTooltipBtn
              btn-class="mr-2"
              color="error"
              icon="mdi-delete"
              :tooltip="`删除此分类`"
              @click="showDeleteLbGroupDialog(item.guid)"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AppDialog
      v-model="isShowLbCategoryCreationDialog"
      size="small"
      :title="`添加新的量表分类`"
      color="green"
      action-text="创建"
      :loading="isDialogLoading"
      @confirm="createNewLbCategory"
      @closed="groupCreationDialogClosed"
    >
      <v-text-field
        v-model="newDisplayName"
        :label="`量表分类名称`"
        autofocus
        :rules="fieldRules.newDisplayName"
      ></v-text-field>
      <v-textarea
        counter
        full-width
        single-line
        :label="`量表分类描述`"
        v-model="newDescription"
      ></v-textarea>
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteLbCategoryDialog"
      color="error"
      size="small"
      :title="`是否确定要删除量表分类 ？`"
      action-text="确认删除"
      :loading="isDialogLoading"
      @confirm="deleteOneLbCategory"
      @closed="deleteLbCategoryDialogClosed"
    >
      删除后不可恢复
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>
<script>
import _ from "lodash";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppDialog from "@/components/AppDialog";
import {
  getLbCategoryList,
  updateLbCategoryDescription,
  updateLbCategoryDisplayName,
  createLbCategory,
  fetchLbCategoryInfo,
  DeleteOneLbCategory
} from "@/api/lb";

import { mapGetters } from "vuex";
export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn,
    AppDialog
  },
  data() {
    return {
      isLoading: false,
      isShowLbCategoryCreationDialog: false,
      isDialogLoading: false,
      isLbCategoryListLoading: false,
      isShowDeleteLbCategoryDialog: false,
      errorMsg: "",
      dataTableKey: 0,
      lbCategorySearchText: "",
      lbCategoryList: [],
      nodeLbCategoryHeaders: [],
      editingText: "",
      newDisplayName: "",
      newDescription: "",
      fieldRules: {
        newDisplayName: [val => (val || "").length > 0 || "名称不能为空"]
      },
      deleteGuid: ""
    };
  },
  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids"
    })
  },
  methods: {
    async deleteOneLbCategory() {
      try {
        this.isDialogLoading = true;
        await DeleteOneLbCategory(this.deleteGuid);
        this.lbCategoryList = _.filter(this.lbCategoryList, o => {
          return o.guid != this.deleteGuid;
        });
        this.isShowDeleteLbCategoryDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    deleteLbCategoryDialogClosed() {
      this.deleteGuid = "";
    },
    showDeleteLbGroupDialog(guid) {
      this.deleteGuid = guid;
      this.isShowDeleteLbCategoryDialog = true;
    },
    async createNewLbCategory() {
      try {
        this.isDialogLoading = true;
        let newGuid = await createLbCategory(
          this.regionGuid,
          this.newDisplayName,
          this.newDescription
        );
        await this.unshiftItemToLbCategoryList(newGuid);
        this.isShowLbCategoryCreationDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    async unshiftItemToLbCategoryList(newGuid) {
      let newLbGroup = await fetchLbCategoryInfo(newGuid);
      this.lbCategoryList.unshift(newLbGroup);
    },
    groupCreationDialogClosed() {
      // 重置控件
      this.newDisplayName = "";
      this.newDescription = "";
    },
    showAddDialog() {
      this.isShowLbCategoryCreationDialog = true;
    },
    editingTextOpen(originalText) {
      this.editingText = originalText;
    },
    editingCancel() {
      this.editingText = "";
    },
    async updateLbCategoryDescription(lbCategoryItem, description) {
      try {
        this.isLoading = true;
        await updateLbCategoryDescription(lbCategoryItem.guid, description);
        lbCategoryItem.description = description;
        this.editingText = "";
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async updateLbCategoryDisplayName(lbCategoryItem, displayName) {
      try {
        this.isLoading = true;
        await updateLbCategoryDisplayName(lbCategoryItem.guid, displayName);
        lbCategoryItem.displayName = displayName;
        this.editingText = "";
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getCategoryList() {
      try {
        this.isLoading = true;
        this.lbCategoryList = await getLbCategoryList(this.regionGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    initNodeLbCategoryHeaders() {
      this.nodeLbCategoryHeaders = [
        {
          text: `量表分类名`,
          value: "displayName",
          width: "300px"
        }
      ];
      this.nodeLbCategoryHeaders.push({
        text: "量表分类描述",
        value: "description"
      });
      this.nodeLbCategoryHeaders.push({
        text: "操作",
        value: "guid",
        sortable: false,
        filterable: false
      });
    },
    //#region Refresh datatable
    getCardTitleHeight() {
      if (this.$refs.cardTitle) {
        return this.$refs.cardTitle.offsetHeight;
      }
      return 0;
    },
    getDataTableHeight() {
      var overflowHeight = 160 + this.getCardTitleHeight();
      return `calc(100vh - ${overflowHeight}px)`;
    }
    //#endregion
  },
  async created() {
    this.initNodeLbCategoryHeaders();
    this.getCategoryList();
  }
};
</script>

<style lang="scss" scoped>
.fixed-card {
  height: calc(100vh - 125px);
}

.lbgroup-search-field {
  max-width: 300px;
}
</style>
